import { baseUrl } from "./BaseUrl";

export const loginApi=baseUrl+"/admin-login" 
export const changePasswordApi=baseUrl+"/change-password"

//=============== Dashboard ================
export const getDashboardapi="/get-dashboard"

//=================== User ==================
export const getUserApi="/get-user"

//=============== AirDrop ==============
export const airdropUSerApi="/airdrop-user"
export const airdropListApi="/airdrop-list"
export const approveAirDropApi="/approve-airdrop"
export const rejectAirDropApi="/rejected-airdrop"

//=============== Transaction ===========
export const getTransactionApi="/get-transaction-list"

//============ withdraw ==============
export const updateWithdrawRejectApi="/update-withdraw-reject"
export const updateWithdrawApproveapi="/update-withdraw-approve"

//============ Claim ==================
export const getClaimRequestApi="/get-claim-request"
export const updateClaimapproveApi="/claim-approve"
