import React, { useEffect,useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../AuthContext";
import $ from "jquery";
import ChangePasswordModal from "../partial/ChangePasswordModal";

function Header() {
  const navigate = useNavigate();
  const { logout } = useAuth();
  const [show, setShow] = useState(false);
  const adminlogout = () => {
    localStorage.clear();
    logout();
    navigate("/");
  };

  useEffect(() => {
    $(document).ready(function () {
      $(".toggle-sidebar-btn").click(function () {
        $(".body").toggleClass("toggle-sidebar");
      });
    });
  }, []);

  const handleShow = (id) => {
    setShow(true);
  };

  return (
    <header id="header" className="header fixed-top d-flex align-items-center">
      <div className="d-flex align-items-center justify-content-between logo-admin">
        <Link to="/dashboard" className="logo d-flex align-items-center">
                <img src="/assets/img/logo.png" alt="" />
                <img
                  src="/assets/img/dollar-icon.png"
                  id="slidecaption"
                  className="dollar-rotate"
                  alt=""
                />
          {/* <span className="d-none d-lg-block">0.3 Verse</span> */}
        </Link>
        <i className="bi bi-list toggle-sidebar-btn"></i>
      </div>
      <nav className="header-nav ms-auto">
        <ul className="d-flex align-items-center">
          {/* <li className="nav-item dropdown">
            <a className="nav-link nav-icon" href="#" data-bs-toggle="dropdown">
              <i className="bi bi-bell"></i>
              <span className="badge bg-primary badge-number">4</span>
            </a>
            <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow notifications">
              <li className="dropdown-header">
                You have 4 new notifications
                <a href="#">
                  <span className="badge rounded-pill bg-primary p-2 ms-2">
                    View all
                  </span>
                </a>
              </li>
              <li>
                <hr className="dropdown-divider" />
              </li>
              <li>
                <hr className="dropdown-divider" />
              </li>
              <li className="notification-item">
                <i className="bi bi-check-circle text-success"></i>
                <div>
                  <h4>Sit rerum fuga</h4>
                  <p>Quae dolorem earum veritatis oditseno</p>
                  <p>2 hrs. ago</p>
                </div>
              </li>
              <li>
                <hr className="dropdown-divider" />
              </li>
            </ul>
          </li> */}
          {/* <li className="nav-item dropdown">
            <a className="nav-link nav-icon" href="#" data-bs-toggle="dropdown">
              <i className="bi bi-chat-left-text"></i>
              <span className="badge bg-success badge-number">3</span>
            </a>
            <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow messages">
              <li className="dropdown-header">
                You have 3 new messages
                <a href="#">
                  <span className="badge rounded-pill bg-primary p-2 ms-2">
                    View all
                  </span>
                </a>
              </li>
              <li>
                <hr className="dropdown-divider" />
              </li>
              <li className="message-item">
                <a href="#">
                  <img
                    src="/assets/img/messages-1.jpg"
                    alt=""
                    className="rounded-circle"
                  />
                  <div>
                    <h4>Maria Hudson</h4>
                    <p>
                      Velit asperiores et ducimus soluta repudiandae labore
                      officia est ut...
                    </p>
                    <p>4 hrs. ago</p>
                  </div>
                </a>
              </li>
              <li>
                <hr className="dropdown-divider" />
              </li>
              <li className="message-item">
                <a href="#">
                  <img
                    src="/assets/img/messages-2.jpg"
                    alt=""
                    className="rounded-circle"
                  />
                  <div>
                    <h4>Anna Nelson</h4>
                    <p>
                      Velit asperiores et ducimus soluta repudiandae labore
                      officia est ut...
                    </p>
                    <p>6 hrs. ago</p>
                  </div>
                </a>
              </li>
            </ul>
          </li> */}
          <li className="nav-item dropdown pe-3">
            <a
              className="nav-link nav-profile d-flex align-items-center pe-0"
              href="#"
              data-bs-toggle="dropdown"
            >
              <img
                src="/assets/img/profile-img.jpg"
                alt="Profile"
                className="rounded-circle"
              />
              <span className="d-none d-md-block dropdown-toggle ps-2">
                Admin
              </span>
            </a>
            <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
              <li className="dropdown-header">
                <h6>Welcome Admin</h6>
             
              </li>
              <li>
                <hr className="dropdown-divider" />
              </li>
             
              <li>
                <Link
                  className="dropdown-item d-flex align-items-center"
                  to=""
                  onClick={() => handleShow()}
                >
                 
                  <span>Change Password</span>
                </Link>
              </li>
              <li>
                <Link
                  className="dropdown-item d-flex align-items-center"
                  to=""
                  onClick={(e) => adminlogout(e)}
                >
                  <i className="bi bi-box-arrow-right"></i>
                  <span>Log Out</span>
                </Link>
              </li>
              <li>
                <hr className="dropdown-divider" />
              </li>
            
              <li>
                <hr className="dropdown-divider" />
              </li>
            </ul>
          </li>
        </ul>
      </nav>
      <ChangePasswordModal show={show} setShow={setShow} logout={logout}/>
    </header>
  );
}

export default Header;
