import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

function ViewImages(props) {
  const handleClose = () => {
    props.setShow(false);
  };

  return (
    <>
      <Modal show={props.show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title style={{ color: "black" }}>Images </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            {props.imageUrls?.map((url, index) => {
              const fileExtension = url.split(".").pop().toLowerCase();

              if (fileExtension === "mp4") {
                // If the file is a video, render the video tag
                return (
                  <div key={index}>
                    <video controls width="500">
                      <source src={url} type="video/mp4" />
                    </video>
                  </div>
                );
              } else if (
                ["png", "jpg", "jpeg", "gif"].includes(fileExtension)
              ) {
                // If the file is an image, render the img tag
                return <img src={url} alt={`Media ${index}`} width="500" />;
              } else {
                // For unsupported file types, render a message
                return (
                  <div key={index}>
                    <p>Unsupported file type</p>
                  </div>
                );
              }
            })}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ViewImages;
