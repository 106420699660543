import React,{useEffect,useState} from "react";
import Footer from "../../widgets/Footer";
import Header from "../../widgets/Header";
import SideBar from "../../widgets/SideBar";
import ReactDatatable from "@mkikets/react-datatable";
import moment from "moment";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { useGetTransactionMutation } from "../../../redux/transactionApi";
import { statusEnum, transType } from "../../constant/enum";
import { useUpdateWithdrawApproveMutation, useUpdateWithdrawRejectedMutation } from "../../../redux/withdrawApi";
import { toast } from 'react-toastify';
import RejectReasonModal from "../../partial/RejectReasonModal";

function WithdrawReport() {
  const [getTransaction, { data:record }] = useGetTransactionMutation();
  const [updateWithdrawRejected]=useUpdateWithdrawRejectedMutation()
  const [updateWithdrawApprove]=useUpdateWithdrawApproveMutation()
  const [data, setData] = useState([])
  const [status, setStatus] = useState("Pending")
  const [rejectShow, setRejectShow] = useState(false);
  const [id, setId] = useState("");
  const [disable, setDisable] = useState(false);
  const [refresh, setRefresh] = useState(false)

  useEffect(() => {
    getTransaction({trans_type:transType.Withdraw})
  }, [refresh])

  useEffect(() => {
    if(record && status){
       const filterData=record?.filter((list)=>{return (list.trans_status==status)})
       setData(filterData)
    }
 }, [record,status])
  

  const columns = [
    {
      key: "srno.",
      text: "Sr.No.",
      className: "sr_no.",
      align: "left",
      sortable: true,
      cell: (row, index) => index + 1,
    },
    {
        key: "wallet_address",
        text: "Wallet Address",
        className: "email",
        align: "left",
        sortable: true,
      },
    {
      key: "doa_token",
      text: "DOA Token",
      className: "name",
      align: "left",
      sortable: true,
    },
    {
      key: "trans_hash",
      text: "Trans Hash ",
      className: "mobile_number",
      align: "left",
      sortable: true,
    },
    {
      key: "trans_status",
      text: "Status",
      className: "mobile_number",
      align: "left",
      sortable: true,
    },
    {
      key: "start_date",
      text: "Date",
      className: "email",
      align: "left",
      sortable: true,
      cell: (record) => {
        const date = record.createdAt;
        return <>{moment(date).format("DD/MM/YYYY")}</>;
      },
    },
    {
        key: "action",
        text: "Action",
        className: `Action ${status}-a`,
        align: `left ${status}-a`,
        sortable: true,
        cell: (record) => {
          return (
            <>
             <button
                onClick={() => {
                  approveAlert(record.id);
                }}
                title="Approve"
                disabled={disable}
                className="btn btn-primary"
              >
                <span className="bi bi-check-lg"></span>
              </button>
                <button
                  onClick={() => {
                    showRejectModal(record.id);
                  }}
                  title="Reject"
                  disabled={disable}
                  className="btn btn-primary ms-2"
                >
                  <span className="bi bi-x"></span>
                </button>
              
             
            </>
          );
        },
      },
  ];

  const config = {
    page_size: 10,
    length_menu: [10, 20, 50],
    filename: "Fund Request List",
    no_data_text: "No record found!",
    button: {
      // print: true,
      // csv: true,
    },
    language: {
      filter: "Search in records...",

      pagination: {
        first: "First",
        previous: "Previous",
        next: "Next",
        last: "Last",
      },
    },
    show_length_menu: true,
    show_filter: true,
    show_pagination: true,
    show_info: true,
  };

  const handleStatus=(value)=>{
    setStatus(value)
  }

  const showRejectModal = (id) => {
    setRejectShow(true);
    setId(id);
  };

  const closeRejectModal = () => {
    setRejectShow(false);
  };

  const handleReject = (reason) => {
    let data = {
      id: id,
      reason: reason,
    };
    setDisable(true);
    updateWithdrawRejected(data).then((result) => {
      if (result.data.status) {
        toast.dismiss();
        toast.success(result.data.message);
        closeRejectModal();
        setDisable(false);
        setRefresh(!refresh)
      } else {
        toast.dismiss();
        toast.error(result.data.message);
        setDisable(false);
      }
    });
  };

  const approveAlert = (id) => {
    confirmAlert({
      title: "Confirm to submit",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => withDarwApprove(id),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const withDarwApprove=(id)=>{
    const data={id}
    setDisable(true)
    updateWithdrawApprove(data).then((result) => {
      if (result.data.status) {
        toast.dismiss();
        toast.success(result.data.message);
        setDisable(false);
        setRefresh(!refresh)
      } else {
        toast.dismiss();
        toast.error(result.data.message);
        setDisable(false);
      }
    });
  }

  return (
    <>
      <Header />
      <SideBar />
      <main id="main" className="main">
        <div className="pagetitle d-flex align-items-center mb-4">
          <h1>Withdraw List</h1>
          <div className="filter-button">
           {statusEnum?.map((list)=>{
            return (<button key={list.key} className={list.value==status?"active":""} onClick={()=>{handleStatus(list.key)}}>{list.value}</button>)
           })}
        </div>
        </div>

        <section className="section dashboard">
          <div className="card">
            <div className="card-body pt-4">
              <ReactDatatable
                config={config}
                records={data}
                columns={columns}
              />
            </div>
          </div>
        </section>
      </main>
      <Footer />
      <RejectReasonModal
        closeRejectModal={closeRejectModal}
        handleReject={handleReject}
        rejectShow={rejectShow}
        disable={disable}
      />
    </>
  );
}

export default WithdrawReport;
