import { getClaimRequestApi, updateClaimapproveApi, updateWithdrawApproveapi } from "../components/constant/Api";
  import { myApi } from "./api";
  
  export const claimApi = myApi.injectEndpoints({
    endpoints: (builder) => ({
      getClaimRequest: builder.query({
        query: (post) => ({
          url: getClaimRequestApi,
          method: "GET",
        }),
        transformResponse: (response, meta, arg) => {
            return response.status ? response?.data ?? [] :[];
          },
          providesTags: (_) => ["claim"],
      }),

      updateClaimApprove: builder.mutation({
        query: (post) => ({
          url: updateClaimapproveApi,
          method: "POST",
          body:post
        }),
        
        invalidatesTags: (_) => ["claim"],
      }),
    }),
  });
  
  export const {useGetClaimRequestQuery,useUpdateClaimApproveMutation} = claimApi;
  