import React from "react";
import { Link } from "react-router-dom";

function SideBar() {
  return (
    <aside id="sidebar" className="sidebar">
      <ul className="sidebar-nav" id="sidebar-nav">
        <li className="nav-item">
          <Link
            className={
              window.location.pathname === "/dashboard"
                ? "nav-link active"
                : "nav-link"
            }
            to="/dashboard"
          >
            <i className="bi bi-grid"></i>
            <span>Dashboard</span>
          </Link>
        </li>
        <li className="nav-item">
          <Link
            className={
              window.location.pathname === "/users"
                ? "nav-link active"
                : "nav-link"
            }
            to="/users"
          >
            <i className="bi bi-megaphone"></i>
            <span>Users</span>
          </Link>
        </li>
        <li className="nav-item">
          <Link
            className={
              window.location.pathname === "/airdrop-users"
                ? "nav-link active"
                : "nav-link"
            }
            to="/airdrop-users"
          >
            <i className="bi bi-megaphone"></i>
            <span>Airdrop Users</span>
          </Link>
        </li>
        <li className="nav-item">
          <Link
            className={
              window.location.pathname === "/airdrop-list"
                ? "nav-link active"
                : "nav-link"
            }
            to="/airdrop-list"
          >
            <i className="bi bi-megaphone"></i>
            <span>Airdrop Request List</span>
          </Link>
        </li>
        <li className="nav-item">
          <Link
            className={
              window.location.pathname === "/claim-report"
                ? "nav-link active"
                : "nav-link"
            }
            to="/claim-report"
          >
            <i className="bi bi-megaphone"></i>
            <span>AirDrop Claim Request</span>
          </Link>
        </li>
        <li className="nav-item">
          <Link
            className={
              window.location.pathname === "/deposit-report"
                ? "nav-link active"
                : "nav-link"
            }
            to="/deposit-report"
          >
            <i className="bi bi-megaphone"></i>
            <span>Deposit Report</span>
          </Link>
        </li>
        
        <li className="nav-item">
          <Link
            className={
              window.location.pathname === "/roi-report"
                ? "nav-link active"
                : "nav-link"
            }
            to="/roi-report"
          >
            <i className="bi bi-megaphone"></i>
            <span>Roi Report</span>
          </Link>
        </li>
        <li className="nav-item">
          <Link
            className={
              window.location.pathname === "/directincome-report"
                ? "nav-link active"
                : "nav-link"
            }
            to="/directincome-report"
          >
            <i className="bi bi-megaphone"></i>
            <span>Direct Income Report</span>
          </Link>
        </li>
        <li className="nav-item">
          <Link
            className={
              window.location.pathname === "/levelincome-report"
                ? "nav-link active"
                : "nav-link"
            }
            to="/levelincome-report"
          >
            <i className="bi bi-megaphone"></i>
            <span>Level Income Report</span>
          </Link>
        </li>
        <li className="nav-item">
          <Link
            className={
              window.location.pathname === "/airdrop-report"
                ? "nav-link active"
                : "nav-link"
            }
            to="/airdrop-report"
          >
            <i className="bi bi-megaphone"></i>
            <span>AirDrop Report</span>
          </Link>
        </li>
        <li className="nav-item">
          <Link
            className={
              window.location.pathname === "/withdraw-report"
                ? "nav-link active"
                : "nav-link"
            }
            to="/withdraw-report"
          >
            <i className="bi bi-megaphone"></i>
            <span>Withdraw Report</span>
          </Link>
        </li>
      </ul>
    </aside>
  );
}

export default SideBar;
