import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { InputValid } from "../validations/InputValid";
function RejectReasonModal(props) {
  const [reason, setReason] = useState("");
  const [reasonErr, setReasonErr] = useState("");
  const handlechange = (e) => {
    const { value, name } = e.target;
    setReason(value);
    let checkValue = InputValid(name, value);
    setReasonErr(checkValue);
  };
  const onSubmit = (e) => {
    e.preventDefault();
    let checkValue = InputValid("reason", reason);
    if (checkValue !== "") {
      setReasonErr(checkValue);
      return false;
    }
    props.handleReject(reason)
    setReason("")
    setReasonErr("")
  };
  return (
    <div>
      <Modal show={props.rejectShow} onHide={props.closeRejectModal}>
        <Modal.Header closeButton>
          {" "}
          <Modal.Title style={{ color: "black" }}>Add Reason</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label> Reason </Form.Label>
              <Form.Control
                name="reason"
                onChange={handlechange}
                type="text"
                value={reason}
              ></Form.Control>
              <span style={{ color: "red" }}>{reasonErr}</span>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.closeRejectModal}>
            Close
          </Button>
          <Button variant="primary" onClick={onSubmit} disabled={props.disable}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default RejectReasonModal;
