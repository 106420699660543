import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useAuth } from "./AuthContext";
import Login from "./components/auth/Login";
import AirDropList from "./components/pages/airDrop/AirDropList";
import AirDropUser from "./components/pages/airDrop/AirDropUser";
import ClaimRequest from "./components/pages/airDrop/ClaimRequest";
import AirdropReport from "./components/pages/report/AirdropReport";
import Deposit from "./components/pages/report/Deposit";
import DirectIncome from "./components/pages/report/DirectIncome";
import LevelIncomeReport from "./components/pages/report/LevelIncomeReport";
import RoiReport from "./components/pages/report/RoiReport";
import WithdrawReport from "./components/pages/report/WithdrawReport";
import Users from "./components/pages/users/Users";
import Dashboard from "./components/widgets/Dashboard";
import ProtectedRoute from "./ProtectedRoute";

function App() {
  const { login } = useAuth();

  useEffect(() => {
    const jwtToken = localStorage.getItem("jwtToken");
    if (jwtToken) {
      login();
    }
  }, [login]);

  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route
            path="/dashboard"
            element={<ProtectedRoute component={<Dashboard />} />}
          />

          <Route
            path="/users"
            element={<ProtectedRoute component={<Users />} />}
          />
          <Route
            path="/airdrop-users"
            element={<ProtectedRoute component={<AirDropUser />} />}
          />
          <Route
            path="/airdrop-list"
            element={<ProtectedRoute component={<AirDropList />} />}
          />
          <Route
            path="/deposit-report"
            element={<ProtectedRoute component={<Deposit />} />}
          />
           <Route
            path="/roi-report"
            element={<ProtectedRoute component={<RoiReport />} />}
          />
          <Route
            path="/directincome-report"
            element={<ProtectedRoute component={<DirectIncome />} />}
          />
           <Route
            path="/airdrop-report"
            element={<ProtectedRoute component={<AirdropReport />} />}
          />
           <Route
            path="/levelIncome-report"
            element={<ProtectedRoute component={<LevelIncomeReport />} />}
          />
           <Route
            path="/withdraw-report"
            element={<ProtectedRoute component={<WithdrawReport />} />}
          />
          <Route
            path="/claim-report"
            element={<ProtectedRoute component={<ClaimRequest />} />}
          />
        </Routes>
      </Router>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
}

export default App;
