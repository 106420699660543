import { airdropListApi, airdropUSerApi, approveAirDropApi, rejectAirDropApi } from "../components/constant/Api";
import { myApi } from "./api";

export const userAirDropApi = myApi.injectEndpoints({
  endpoints: (builder) => ({
    getAirDropUser: builder.query({
      query: () => ({
        url: airdropUSerApi,
        method: "GET",
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response?.data ?? [] :[];
      },
    }),
    getAirDropList: builder.query({
      query: () => ({
        url: airdropListApi,
        method: "GET",
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response?.data ?? [] :[];
      },
      providesTags:()=>["airdrop"]
    }),
    setApproveAirDrop: builder.mutation({
      query: (post) => ({
        url: approveAirDropApi,
        method: "POST",
        body:post
      }),
      
      invalidatesTags:()=>["airdrop"]
    }),
    setRejectAirDrop: builder.mutation({
      query: (post) => ({
        url: rejectAirDropApi,
        method: "POST",
        body:post
      }),
      
      invalidatesTags:()=>["airdrop"]
    }),
  }),
});

export const {useGetAirDropListQuery,useGetAirDropUserQuery,useSetApproveAirDropMutation,useSetRejectAirDropMutation } = userAirDropApi;