export const transType = {
  Buy: "Buy",
  DirectIncome: "Direct Income",
  LevelIncome: "Level Income",
  RoiIncome: "Roi Income",
  Unstake: "Unstake",
  Withdraw: "Withdraw",
  Airdrop: "Airdrop",
};

export const statusEnum=[{
   value:"Pending",
   key:"Pending"
},
{
  value:"Completed",
  key:"Completed"
},
{
  value:"Rejected",
  key:"Rejected"
},
]


export const claimStatusEnum=[{
  value:"Pending",
  key:"Pending"
},
{
 value:"Completed",
 key:"Completed"
},
]


