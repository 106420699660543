import { updateWithdrawApproveapi, updateWithdrawRejectApi } from "../components/constant/Api";
  import { myApi } from "./api";
  
  export const withdrawApi = myApi.injectEndpoints({
    endpoints: (builder) => ({
      updateWithdrawRejected: builder.mutation({
        query: (post) => ({
          url: updateWithdrawRejectApi+"/"+post.id,
          method: "PUT",
          body:post
        }),
      }),
      updateWithdrawApprove: builder.mutation({
        query: (post) => ({
          url: updateWithdrawApproveapi,
          method: "POST",
          body:post
        }),
        
        invalidatesTags: (_) => ["record"],
      }),
    }),
  });
  
  export const {useUpdateWithdrawRejectedMutation,useUpdateWithdrawApproveMutation } = withdrawApi;
  