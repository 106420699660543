import React, { useState, useEffect } from "react";
import Footer from "../../widgets/Footer";
import Header from "../../widgets/Header";
import SideBar from "../../widgets/SideBar";
import ReactDatatable from "@mkikets/react-datatable";
import moment from "moment";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { toast } from "react-toastify";
import { claimStatusEnum } from "../../constant/enum";
import {
  useGetClaimRequestQuery,
  useUpdateClaimApproveMutation,
} from "../../../redux/claimApi";

function ClaimRequest() {
  const { data: record } = useGetClaimRequestQuery();
  const [updateClaimApprove] = useUpdateClaimApproveMutation();
  const [status, setStatus] = useState("Pending");
  const [disable, setDisable] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (record && status) {
      const filterData = record?.filter((list) => {
        return list.status == status;
      });
      setData(filterData);
    }
  }, [record, status]);

  const columns = [
    {
      key: "srno.",
      text: "Sr.No.",
      className: "sr_no.",
      align: "left",
      sortable: true,
      cell: (row, index) => index + 1,
    },

    {
      key: "wallet_address",
      text: "Wallet Address",
      className: "email",
      align: "left",
      sortable: true,
    },

    {
      key: "type",
      text: "Type",
      className: "email",
      align: "left",
      sortable: true,
    },
    {
      key: "point",
      text: "Points",
      className: "email",
      align: "left",
      sortable: true,
    },
    {
      key: "start_date",
      text: "Start Date",
      className: "email",
      align: "left",
      sortable: true,
      cell: (record) => {
        const date = record.createdAt;
        return <>{moment(date).format("DD/MM/YYYY")}</>;
      },
    },

    {
      key: "action",
      text: "Action",
      className: `Action  ${status}-a`,
      align: `left ${status}-a`,
      sortable: true,
      cell: (record) => {
        return (
          <>
            <button
              style={{
                border: "1px solid #fff",
                borderRadius: "3px",
                background: "green",
                padding: "0px 7px",
              }}
              onClick={() => {
                updateAlert(record.id);
              }}
              disabled={disable}
            >
              <i className="bi bi-check" style={{ color: "#fff" }}></i>
            </button>
          </>
        );
      },
    },
  ];

  const config = {
    page_size: 10,
    length_menu: [10, 20, 50],
    filename: "Fund Request List",
    no_data_text: "No record found!",
    button: {
      // print: true,
      // csv: true,
    },
    language: {
      filter: "Search in records...",

      pagination: {
        first: "First",
        previous: "Previous",
        next: "Next",
        last: "Last",
      },
    },
    show_length_menu: true,
    show_filter: true,
    show_pagination: true,
    show_info: true,
  };

  const updateAlert = (id) => {
    confirmAlert({
      title: "Confirm to submit",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            claimApprove(id);
          },
        },
        {
          label: "No",
        },
      ],
    });
  };
  const handleStatus = (value) => {
    setStatus(value);
  };

  const claimApprove = (id) => {
    const data = { id };
    setDisable(true);
    updateClaimApprove(data).then((result) => {
      if (result.data.status) {
        toast.dismiss();
        toast.success(result.data.message);
        setDisable(false);
      } else {
        toast.dismiss();
        toast.error(result.data.message);
        setDisable(false);
      }
    });
  };

  return (
    <>
      <Header />
      <SideBar />
      <main id="main" className="main">
        <div className="pagetitle d-flex align-items-center mb-4">
          <h1>AirDrop Claim List</h1>
          <div className="filter-button">
            {claimStatusEnum?.map((list) => {
              return (
                <button
                  key={list.key}
                  className={list.value == status ? "active" : ""}
                  onClick={() => {
                    handleStatus(list.key);
                  }}
                >
                  {list.value}
                </button>
              );
            })}
          </div>
        </div>

        <section className="section dashboard">
          <div className="card">
            <div className="card-body pt-4">
              <ReactDatatable
                config={config}
                records={data}
                columns={columns}
              />
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}

export default ClaimRequest;
